import React,{useState }  from 'react';
import emailjs from '@emailjs/browser';
import "./ContactFormStyles.css";

const EmailForm = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [topping,setTopping] = useState(''); 


    const onOptionChange = (e) => {
      setTopping(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = 'service_0yhb1q4';
        const templateId = 'template_5hn48jv';
        const publicKey = 'iQpL29NrDsmwWSVSy';

        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'Web Wizard',
            massage: message,
            // topping: topping, 
            

        };

        emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) =>  {
            console.log('Email sent successfully!', response);
            setName('');
            setEmail('');
            setMessage('');
            {/* onOptionChange(''); */}

        })
        .catch((error) => {
            console.error('Error sending email: ', error);
        });
    }

    


  return (
    <form onSubmit={handleSubmit} className='emailForm'>
      <h1>Contact Form</h1>
        <input 
          type="text"
          placeholder="your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          />
          <input
          type="email"
          placeholder="your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          />
          

          <textarea
          cols="30"
          rows="10"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit">Send Email</button>

    </form>
  )
}

export default EmailForm