import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import AboutContent from "../components/AboutContent";
import Slider from "../components/Slider";
const About = () => {
  return (
    <div>
       
      <Navbar />
       <HeroImg2
       heading="ABOUT."
       text="we are a company especialized in high quality cleaning services "
      />
      <AboutContent />
     <Slider />
  <Footer />
    </div>
  );
};

export default About;