import "./FooterStyles.css";

import {
  FaPhone,
  FaMailBulk,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <h3 font-weight="bold" font-size="2rem">
            <a href="/Project">Services</a>
          </h3>
          <p>
            <br/>
            <a href="/Project" className="text-reset">
              Deep Cleaning
            </a>
          </p>
          <p>
            <a href="/Project" className="text-reset">
              Post-Renovation Cleaning
            </a>
          </p>
          <p>
            <a href="/Project" className="text-reset">
              Regular weekly cleaning
            </a>
          </p>
          <p>
            <a href="/Project" className="text-reset">
              Special Event Cleaning
            </a>
          </p>
          <p>
            <a href="/Project" className="text-reset">
              Offices cleaning
            </a>
          </p>
          <p>
            <a href="/Project" className="text-reset">
              Vacations Rentals Cleaning
            </a>
          </p>
        </div>

        <div className="center">
          <h3 font-weight="bold" text-align="center">
            <a href="/Project">About us</a>
          </h3>
          <p >
            <br />
            Welcome to Elizabeth Sparkling Services, your trusted partner in
            home cleaning excellence. With a decade of dedicated experience, we
            bring a touch of brilliance to every corner of New York City and
            Long Island.{" "}
          </p>
          <p>
            
          </p>
          <div className="social">
            <FaFacebook
              size={30}
              style={{
                color: "#fff",
                marginRight: "3rem",
              }}
            />
            <FaTwitter
              size={30}
              style={{ color: "#fff", marginRight: "3rem" }}
            />
            <FaLinkedin
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
            />
          </div>
        </div>

        <div className="right">
          <div>
            <h3> Contact </h3>
            <br />
          </div>
          <div className="phone">
            <p>
              <FaPhone
                size={30}
                style={{
                  color: "#fff",
                  marginRight: "1rem",
                  marginLeft: "0rem",
                }}
              />
              Call us
            </p>
            <p>
              <br /> (631) 123 4564
            </p>
          </div>

          <div className="email">
            <p>
              <br />
              <a href="/Contact">
                <FaMailBulk
                  size={30}
                  style={{ color: "#fff", marginRight: "1rem",
                  marginLeft: "0rem", }}
                />
              </a>

              <a href="/Contact">Email us</a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer-end">
        <br />
        <br />
        <br />
        <h5 text-align="center" color="#fff">
          {`Copyright © Sparkling Cleaning Services ${year}`}
        </h5>
      </div>
    </div>
  );
};

export default Footer;
