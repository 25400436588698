import "./HeroImgStyles.css";
import React from "react";
import IntroImg from "../assets/2881.jpg";
import { Link } from "react-router-dom";

const HeroImg = () => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="into-img" src={IntroImg} alt="IntroImg" />
      </div>
      <div className="content">
        <h1> Revitalize Your Space, Reclaim Your Time </h1>
        <div>
         
          <Link to="contact" className="btn btn-light">
            Contact us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;
