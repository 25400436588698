import "./AboutContentStyles.css";
import { Link } from "react-router-dom";
import AboutUSCleaningServices from "../assets/AboutUSCleaningServices.jpeg";

import React from "react";


const AboutContent = () => {
  return (
    <div className="about">
      <div className="leftt">
        <h1>Who are we? </h1>
        <p>
          Welcome to Elizabeth Sparkling cleaning, your trusted partner for
          proffessional home cleaning services in New york. With our exceptional
          attention to detail and commitment to customer satisfaction, we strive
          to create clean and healthy living spaces for our valued clients.
        </p>

        <p>
          {" "}
          Our team of dedicated cleaning experts is hihly trained and equipped
          with the latest tools and teachniques to deliver top-notch cleaning
          services tailored to meet your specific needs. Whether you require
          regular maintenance cleaning or a deep clean, you can rely on us to
          provide reliable, efficient, and thorough cleaning solutions that
          exceed your expectations.
        </p>
        <Link to="/contact">
          <button className="bttn"> Contact </button>
        </Link>
      </div>
      <div className="rightt">
        <div className="img-container">
          {/*  <div className="img-stack top">
            
          </div>
          <div className="img-stack bottom">
            <img src={pic8} className="img" alt="true" />
          </div>
  */}
          <img src={AboutUSCleaningServices} className="img" alt="AboutUSCleaningServices" />
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
