import React from "react";
import Navbar from "../components/Navbar";
import HeroImg from "../components/HeroImg";
import Footer from "../components/Footer";
import HomeContent from "../components/HomeContent";

//import Work from "../components/Work";

const Home = () => {
    return (
      <div>
        <Navbar />
        <HeroImg />
        <HomeContent/>
        <Footer />
        {/*
       
        
        <Work />
        <Footer />
    */}
      </div>
    );
  };
  
  export default Home;