import "./WorkCardStyles2.css";
import React from "react";
import { NavLink } from "react-router-dom";

const WorkCard2 = (props) => {
  return (
    <div className="project-card">
      
      <div className="imagewrap">
      <div className="imagencard caja">
      <img src={props.imgsrc} alt="image" />
      </div>
      </div>

      <div className="project-titulo">
        <h3 className="project-title">{props.title}</h3>
      </div>
      <div className="pro-details">
        <p>{props.text}</p>
      </div>
      <div className="pro-btns">
        <div className="pro-center">
          <NavLink to={props.view} className="btn">
            <a>Learn More</a>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WorkCard2;
