import "./WorkCardStyles2.css";
import SpecialEvent from "../assets/HomeCleaning66.jpeg";
import Vacation from "../assets/HomeCleaning77.jpeg";
import RegularCleaning from "../assets/HomeCleaning55.jpeg";
import DeepCleaning from "../assets/DeepCleaning.jpeg";
import PostRenovation from "../assets/PostRenovationCleaning.jpeg";
import OfficeCleaning from "../assets/OfficeCleaning.jpeg";


import React from "react";

const ProjectCardData = [
  {
    imgsrc: DeepCleaning,
    title: "Deep Cleaning",
    text:
      "Our deep cleaning service is designed to give your home a thorough and comprehensive clean. We go beyond the surface to tackle hidden dirt and grime, paying attention to often overlooked areas. From scrubbing and sanitizing bathrooms to dusting and vacuuming hard-to-reach corners, our deep cleaning service ensures a refreshed and revitalized living space.",
    view: "/Contact"
  },
  {
    imgsrc: PostRenovation,
    title: "Post-Renovation Cleaning",
    text:
      "After the dust settles from a renovation project, our post-renovation cleaning service steps in to restore your home to its pre-construction cleanliness. We specialize in removing construction debris, dust, and residue, leaving your space spotless. Our attention to detail ensures that every nook and cranny is meticulously cleaned, allowing you to enjoy your newly renovated space without the hassle of cleanup.",
    view: "/Contact"
  },
  {
    imgsrc: RegularCleaning,
    title: "regular weekly cleaning ",
    text:
      "Our regular weekly cleaning service is designed to maintain a consistently clean and organized home. Our professional cleaners follow a tailored cleaning checklist to address your specific needs. From vacuuming and mopping floors to dusting surfaces and tidying up common areas, we ensure that your home stays pristine week after week, providing you with a comfortable and inviting living environment.",
    view: "/Contact"
  },
  {
    imgsrc: OfficeCleaning,
    title: "offices cleaning",
    text:
      "Create a conducive and productive work environment with our office cleaning services. We understand the importance of a clean workspace for employee well-being and business success. Our office cleaning services cover all aspects, including dusting, sanitizing, trash removal, and maintaining common areas. Tailored to your office's unique requirements, our services contribute to a professional and hygienic workplace.",
    view: "/Contact"
  },
  {
    imgsrc: SpecialEvent,
    title: "special Event Cleaning ",
    text:
      "Make your special events memorable for all the right reasons with our event cleaning services. Whether it's a corporate gathering, wedding, or any special occasion, we ensure that your venue is immaculate before, during, and after the event. Our team works efficiently to handle pre-event setup, ongoing maintenance, and post-event cleanup, allowing you to focus on enjoying the occasion without worrying about the mess.",
    view: "/Contact"
  },
  {
    imgsrc: Vacation,
    title: "Vacations Rentals Cleaning ",
    text:
      "Indulge in the luxury of a spotless retreat with our Vacation Rentals Cleaning Service. We specialize in transforming your vacation property into a pristine haven, ensuring that each guest enjoys a seamless and memorable stay. From cozy cabins to opulent villas, our tailored cleaning packages are designed to meet the unique needs of your property, guaranteeing a quick turnaround between guest stays. Experience the pinnacle of cleanliness and hospitality with our Vacation Rentals Cleaning Service—because every getaway deserves a sparkling welcome.",
    view: "/Contact"
  }
];

export default ProjectCardData;
