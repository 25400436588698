const people = [
    {
      id: 1,
      name: "Lena Smith",
      title: "",
      quote:
        "I am extremely grateful for the exceptional service provided by Elizabeth sparkling Cleaning. They transformed my messy home into a spotless sanctuary. Their attention  to detail and profesionalism exceeded my expectations. Thank you for making my ife easier and my home sparkle! "
    },
    {
      id: 2,
      name: "Emily ",
      title: "",
      quote:
        "Elizabeth Sparkling cleaning did an outstanding job cleaning my home! Their  "
    },
    {
      id: 3,
      name: "Dan",
      title: "",
      quote:
        "I am extremely grateful for the exceptional service provided by Elizabeth sparkling Cleaning. They transformed my messy home into a spotless sanctuary. Their attention  to detail and profesionalism exceeded my expectations. Thank you for making my ife easier and my home sparkle! "
    }
  ];
  
  export default people;
  