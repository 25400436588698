import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import EmailForm from "../components/EmailForm";


//import Form from "../components/Form";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2 heading="CONTACT US" text="Lets have a chat" />
      
      <EmailForm/>
      {/* 
      <Form />
      */} 
      <Footer />
    </div>
  );
};

export default Contact;
