import './index.css';
import React from 'react';
import About from "./routes/About";
import Home from "./routes/Home";
import Service from "./routes/Service";
import Contact from "./routes/Contact";
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">
       <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Service/>} />
        {/*}
        
       
        <Route path="/testlab" element={<TestLab />} />
  */}
      </Routes>
    </>
     
    </div>
  );
}

export default App;
