import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import Work2 from "../components/Work2";

const Service = () => {
  return (
    <div>
      <Navbar />
      <HeroImg2 heading="Refresh your home with professional Cleaning" text="" />
      <Work2/>
      <Footer />
    </div>
  );
};

export default Service;
