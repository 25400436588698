import "./HomeContentStyles.css";
import React from "react";
import IntroImg from "../assets/2881.jpg";
import SpecialEvent from "../assets/HomeCleaning66.jpeg";
import Vacation from "../assets/HomeCleaning77.jpeg";
import RegularCleaning from "../assets/HomeCleaning55.jpeg";
import DeepCleaning from "../assets/DeepCleaning.jpeg";
import PostRenovation from "../assets/PostRenovationCleaning.jpeg";
import OfficeCleaning from "../assets/OfficeCleaning.jpeg";






const HomeContent = () => {
  return (
    <div>

    <div class="container1">
    <div class="row1">
      <div class="column-66">
      <h1 class="xlarge-font">
          <b>special Event Cleaning</b>
        </h1>
        <p class="fontlink">
          Ensure your special moments shine with our impeccable Special
          Event Cleaning service. From pre-event sparkle to post-celebration
          clarity, we specialize in creating a spotless canvas for your
          memorable occasions. Let us handle the details, so you can focus
          on making memories. Elevate your events with our expert touch and
          leave the cleaning to us.
        </p>
      
      </div>
      <div class="column-33">
        <img src={SpecialEvent} alt="SpecialEvent" />
      </div>
    </div>
  </div>

    
     
      
      <div class="container1">
        <div class="row2">
          <div class="column-99">
            <img src={RegularCleaning} alt="RegularCleaning" />
          </div>
          <div class="column-88">
          <h1 class="xlarge-font">
              <b>regular weekly cleaning</b>
            </h1>
            <p class="fontlink">
              Experience the luxury of a consistently pristine home with our
              Weekly Cleaning service. Our dedicated team ensures every corner
              is meticulously cleaned, creating a haven of comfort for you to
              enjoy. Embrace the convenience of a sparkling home week after
              week, tailored to your needs. Elevate your lifestyle with our
              reliable and expertly crafted regular cleaning services.
            </p>

          
          </div>
        </div>
      </div>

     
      <div class="container1">
        <div class="row1">
          <div class="column-66">
          <h1 class="xlarge-font">
              <b>Office cleaning</b>
            </h1>
            <p class="fontlink">
              <br />
              Elevate your workspace with our professional Office Cleaning
              service. Impeccable cleanliness fosters a productive environment,
              and our expert team ensures every nook and cranny reflects the
              highest standards. Experience the transformative power of a
              spotless office, promoting efficiency and employee well-being.
              Choose excellence, choose our tailored office cleaning solutions
              for a workspace that truly shines.
            </p>
          
          </div>
          <div class="column-33">
            <img src={OfficeCleaning} alt="OfficeCleaning" />
          </div>
        </div>
      </div>

     

      <div class="container1">
        <div class="row2">
          <div class="column-99">
            <img src={Vacation} alt="Vacation" />
          </div>
          <div class="column-88">
          <h1 class="xlarge-font">
              <b>Vacations Rentals Cleaning</b>
            </h1>
            <p class="fontlink">
              <br />
              We ensure your rental property is impeccably cleaned and ready for
              guests.
            </p>

            <p class="fontlink">
              Immerse your visitors in a spotless, welcoming space, enhancing
              their overall stay.
            </p>
            <p class="fontlink">
              Experience the epitome of cleanliness with our tailored cleaning
              solutions.
            </p>
          </div>
        </div>
      </div>

     

      <div>
      <div class="container1">
        <div class="row1">
          <div class="column-66">
          <div>
              <br />
              <h1 class="xlarge-font">
                <b>Deep Cleaning</b>
              </h1>
              <p class="fontlink">
                <br />
                Our adept team employs meticulous precision to rejuvenate your
                living spaces, ensuring an unparalleled standard of cleanliness.
                Elevate your home's ambiance with our comprehensive approach,
                addressing every nook and cranny with sophistication.
              </p>
            </div>
          </div>
          <div class="column-33">
            <img src={DeepCleaning} alt="DeepCleaning" />
          </div>
        </div>
      </div>

      

      <div class="container1">
        <div class="row2">
          <div class="column-99">
            <img src={PostRenovation} alt="PostRenovation" />
          </div>
          <div class="column-88">
          <h1 class="xlarge-font">
              <b>Post Renovation Cleaning</b>
            </h1>
            <p class="fontlink">
              <br />
              Unveil the true beauty of your renovated space with our meticulous
              post-renovation cleaning services. From dust to detail, we
              specialize in restoring your home to a pristine state. Elevate
              your living experience and step into a fresh, flawless environment
              with our expert touch. Transform your space with the finishing
              touch it deserves.
            </p>
          </div>
        </div>
      </div>

      
    </div>

    </div>
  );
};

export default HomeContent;
